<template>
    <div>
        <v-snackbar v-model="snackbar" :top="true" :color="color" :timeout="6000">
            <span v-if="snackbar_msg!=null">{{ snackbar_msg }}</span>
            <v-btn dark text @click="snackbar = false">Close</v-btn>
        </v-snackbar>
        <v-row class="bradcrumb">
            <router-link :to="{name: 'home' }" class="root">
                Dashboard
            </router-link>
            <!-- /
            <router-link :to="{name: 'admin-master' }" class="root">
                Admin Master
            </router-link> -->
            <span class="child">/ Add Library </span>
        </v-row>
        <br />
        <v-card class="card">
            <v-card-title class="heading justify-center">Import Library Details</v-card-title>
            <v-card-text>
                <div class="add-section">
                    <v-row class="justify-content-between set-btn">
                        <div><a href="https://library-public.s3.ap-south-1.amazonaws.com/sample_csv/library_import_csv.csv">
                            <v-btn class="mb-2 button"> <v-icon left dark>mdi-cloud-download</v-icon>Export CSV Template</v-btn></a>
                        </div>
                        <div>
                            <v-btn class="mb-2 button" @click="addOrg">Add Library</v-btn>
                        </div>
                    </v-row>
                    <p class="file-warn">NOTE: *File should be in CSV format.</p>

                    <v-row class="justify-content-center">
                        <v-col lg="5" sm="12" md="5" class="import-box">
                            <label class="label">IMPORT CSV</label>
                            <v-file-input
                                dense
                                v-model="file"
                                :rules="[v => !!v || 'File required']"
                                persistent-hint
                                color="rgb(1, 127, 152)"
                                label="Choose File"
                                accept=".csv"
                                prepend-icon="mdi-paperclip"
                                :error-messages="!fileStatus?'File Required':''"
                                outlined
                                show-size
                            >
                            </v-file-input>
                            <v-btn :loading="output_load" @click="importCsv" class="button">
                                Import
                                <v-icon right>mdi-cloud-upload</v-icon>
                            </v-btn>
                        </v-col>
                    </v-row>
                </div>

                <template>
                    <h5 v-if="failList != null" class="mt-4" style="text-align: center; color: red;">Failed Record</h5>
                    <v-simple-table v-if="failList != null">
                        <template v-slot:default>
                            <thead>
                                <tr>
                                    <th class="text-left">
                                        Name
                                    </th>
                                    <th class="text-left">Address</th>
                                    <th class="text-left">City</th>
                                    <th class="text-left">State</th>
                                    <th class="text-left">Country</th>
                                    <th class="text-left">Pin</th>
                                    <th class="text-left">Contact</th>
                                    <th class="text-left">Reason</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="item in failList" :key="item.name">
                                    <td>{{ item.name }}</td>
                                    <td>{{ item.address }}</td>
                                    <td>{{ item.city }}</td>
                                    <td>{{ item.state }}</td>
                                    <td>{{ item.country }}</td>
                                    <td>{{ item.pin }}</td>
                                    <td>{{ item.contact }}</td>
                                    <td style="color: red;">{{ item.reason }}</td>
                                </tr>
                            </tbody>
                        </template>
                    </v-simple-table>
                </template>

                <v-data-table :headers="headers" :items="lib_list" class="elevation-1 nowrap" :search="search">
                    <template v-slot:top>
                        <v-toolbar flat color="white">
                            <v-spacer></v-spacer>
                            <v-text-field v-model="search" label="Search" append-icon="mdi-magnify" single-line hide-details> </v-text-field>
                            <span style="width: 20px;"></span>
                            <!-- <v-btn class="mb-2 button" @click="addOrg">Add Organization</v-btn> -->
                            <v-dialog v-model="dialog" max-width="500px">
                                <v-card>
                                    <v-form v-model="valid" ref="form">
                                        <v-card-title class="heading">
                                            <span class="headline">{{ formTitle }}</span>
                                        </v-card-title>

                                        <v-card-text>
                                            <v-container>
                                                <v-row>
                                                    <v-col cols="12" sm="12" md="12" class="fields">
                                                        <label>Name</label>
                                                        <v-text-field
                                                            dense
                                                            outlined
                                                            :disabled="!inSave"
                                                            v-model="editedItem.name"
                                                            item-text="temp_id"
                                                            item-value="id"
                                                            persistent-hint
                                                            :error="errorMsg.name?true:false"
                                                            :error-messages="errorMsg.name?'Required':''"
                                                            :rules="[v => !!v || 'required']"
                                                        ></v-text-field>
                                                    </v-col>
                                                    <v-col cols="12" sm="12" md="12" class="fields">
                                                        <label>Address</label>
                                                        <v-text-field
                                                            dense
                                                            outlined
                                                            v-model="editedItem.address"
                                                            item-text="temp_id"
                                                            item-value="id"
                                                            persistent-hint
                                                            :error="errorMsg.address?true:false"
                                                            :error-messages="errorMsg.address?'Required':''"
                                                            :rules="[v => !!v || 'required']"
                                                        ></v-text-field>
                                                    </v-col>
                                                    <v-col cols="12" sm="12" md="12" class="fields">
                                                        <label>City</label>
                                                        <v-text-field
                                                            dense
                                                            outlined
                                                            v-model="editedItem.city"
                                                            item-text="temp_id"
                                                            item-value="id"
                                                            persistent-hint
                                                            :error="errorMsg.city?true:false"
                                                            :error-messages="errorMsg.city?'Required':''"
                                                            :rules="[v => !!v || 'required']"
                                                        ></v-text-field>
                                                    </v-col>
                                                    <v-col cols="12" sm="12" md="12" class="fields">
                                                        <label>State</label>
                                                        <v-text-field
                                                            dense
                                                            outlined
                                                            rows="3"
                                                            v-model="editedItem.state"
                                                            item-text="temp_id"
                                                            item-value="id"
                                                            persistent-hint
                                                            :error="errorMsg.state?true:false"
                                                            :error-messages="errorMsg.state?'Required':''"
                                                            :rules="[v => !!v || 'required']"
                                                        ></v-text-field>
                                                    </v-col>

                                                    <v-col cols="12" sm="12" md="12" class="fields">
                                                        <label>Country</label>
                                                        <v-text-field
                                                            dense
                                                            outlined
                                                            rows="3"
                                                            v-model="editedItem.country"
                                                            item-text="temp_id"
                                                            item-value="id"
                                                            persistent-hint
                                                            :error="errorMsg.country?true:false"
                                                            :error-messages="errorMsg.country?'Required':''"
                                                            :rules="[v => !!v || 'required']"
                                                        ></v-text-field>
                                                    </v-col>
                                                    <v-col cols="12" sm="12" md="12" class="fields">
                                                        <label>Pin</label>
                                                        <v-text-field
                                                            dense
                                                            outlined
                                                            rows="3"
                                                            v-model="editedItem.pin"
                                                            item-text="temp_id"
                                                            item-value="id"
                                                            persistent-hint
                                                            :error="errorMsg.pin?true:false"
                                                            :error-messages="errorMsg.pin?'Required':''"
                                                            :rules="[v => !!v || 'required']"
                                                        ></v-text-field>
                                                    </v-col>
                                                    <v-col cols="12" sm="12" md="12" class="fields">
                                                        <label>Contact</label>
                                                        <v-text-field
                                                            dense
                                                            outlined
                                                            rows="3"
                                                            v-model="editedItem.contact"
                                                            item-text="temp_id"
                                                            item-value="id"
                                                            persistent-hint
                                                            :error="errorMsg.contact?true:false"
                                                            :error-messages="errorMsg.contact?'Required':''"
                                                            :rules="[v => !!v || 'required']"
                                                        ></v-text-field>
                                                    </v-col>
                                                </v-row>
                                            </v-container>
                                        </v-card-text>

                                        <v-card-actions>
                                            <v-spacer></v-spacer>
                                            <v-btn class="button-cancel" text @click="close">Cancel</v-btn>
                                            <v-btn class="button" @click="save" :disabled="!valid">Save</v-btn>
                                        </v-card-actions>
                                    </v-form>
                                </v-card>
                            </v-dialog>
                        </v-toolbar>
                    </template>
                    <template v-slot:item.action="{ item }">
                        <div>
                            <v-icon @click="editItem(item)">mdi mdi-pencil-box</v-icon>
                        </div>
                    </template>
                    <template v-slot:item.edit="{ item }">
                        <v-switch v-model="item.isactive" @change="activeOn(item)"></v-switch>
                    </template>
                </v-data-table>
            </v-card-text>
        </v-card>
        <v-overlay :value="overlay">
            <v-progress-circular indeterminate size="64"></v-progress-circular>
        </v-overlay>
    </div>
</template>

<script>
    import axios from "axios";
    import "@/assets/css/color.css"; // css needs to be imported for each dashboard
    export default {
        data: () => ({
            valid: false,
            overlay: false,
            errorMsg: {
                roleLinkId: false,
            },
            failList: null,
            snackbar_msg: "",
            color: "",
            snackbar: false,
            search: "",
            org: "",
            file: null,
            communicationMethod: [],
            inSave: true,
            dialog: false,
            headers: [
                {
                    text: "Sr No",
                    align: "left",
                    sortable: true,
                    value: "srno",
                },
                {
                    text: "Name",
                    align: "left",
                    sortable: true,
                    value: "name",
                },
                {
                    text: "Address",
                    align: "left",
                    sortable: true,
                    value: "address",
                },
                {
                    text: "City",
                    align: "left",
                    sortable: true,
                    value: "city",
                },

                {
                    text: "State",
                    align: "left",
                    sortable: true,
                    value: "state",
                },
                {
                    text: "Country",
                    align: "left",
                    sortable: true,
                    value: "country",
                },

                {
                    text: "Pin",
                    align: "left",
                    sortable: true,
                    value: "pin",
                },

                {
                    text: "Contact",
                    align: "left",
                    sortable: true,
                    value: "contact",
                },
                { text: "Actions", value: "action", sortable: false },
                { text: "Active", value: "edit", sortable: false },
            ],
            bookCategoryList: [],
            notifyTypes: [],
            fileStatus: true,
            lib_list: [],
            role_list: [],
            editedIndex: -1,
            editedItem: {
                name: "",
                address: "",
                email: "",
                contact: "",
                country: "",
                pin: "",
                city: "",
                state: "",
                isactive: false,
                id: "",
            },
            defaultItem: {
                name: "",
                address: "",
                email: "",
                contact: "",
                country: "",
                pin: "",
                city: "",
                state: "",
                isactive: false,
                id: "",
            },
        }),

        computed: {
            formTitle() {
                return this.editedIndex === -1 ? "New Library" : "Edit Library";
            },
        },

        // watch: {
        //     dialog(val) {
        //         val || this.close();
        //     },
        //     overlay(val) {
        //         val = () => {
        //             this.overlay = false;
        //         };
        //     },
        // },
        mounted() {
            this.onLoad();
        },
        methods: {
            importCsv() {
                // const data = {
                //     data:'data fake'
                // }
                let formData = new FormData();
                if (this.file !== null) {
                   if (!(this.file.name.toLowerCase().includes(".csv") || this.file.name.toLowerCase().includes(".CSV"))) {
                       this.showSnackbar("red", "File must be in CSV format Only.!");
                   } else{
                        this.fileStatus = true;
                        formData.append("file", this.file);

                        axios
                            .post("Admin/importLibrary", formData, {
                                headers: {
                                    "Content-Type": "multipart/form-data",
                                },
                            })
                            .then((res) => {
                                this.failList = res.data.failList;
                                if (res.data.status == "200") {
                                    this.showSnackbar("#4caf50", "Library added successfully...");
                                    this.onLoad();
                                }
                                this.file = null;
                            })
                            .catch(function () {
                                this.showSnackbar("#b71c1c", "Something went wrong..."); // show snackbar
                            })
                            .finally(() => {
                                this.output_load = false;
                            });
                   }
                } else {
                    this.fileStatus = false;
                    this.showSnackbar("#b71c1c", "Please select file");
                }
            },
            addOrg() {
                this.inSave = true;
                this.dialog = true;
                this.$refs.form.reset();
            },
            editItem(item) {
                this.inSave = false;
                this.editedIndex = this.lib_list.indexOf(item);
                this.editedItem = Object.assign({}, item);
                this.dialog = true;
                var name = item.role;
                for (let x in this.role_list) {
                    if (this.role_list[x].name == name) {
                        this.editedItem.role = this.role_list[x].name;
                        this.editedItem.roleId = this.role_list[x].id;
                        break;
                    }
                }
            },
            onLoad() {
                this.overlay = true;
                axios
                    .post("/Admin/getLibraryData")
                    .then((res) => {
                        if (res.data.msg == "200") {
                            this.overlay = false;
                            this.lib_list = res.data.lib_list;
                            this.communicationMethod = res.data.communicationMethod;
                            this.notifyTypes = res.data.notifyTypes;
                        }
                    })
                    .catch((error) => {
                        this.showSnackbar("#b71c1c", "Something went wrong..."); // show snackbar
                        window.console.log(error);
                    })
                    .finally(() => {
                        // var overlay = false;
                    });
            },
            showSnackbar(clr, msg) {
                this.snackbar = true;
                this.color = clr;
                this.snackbar_msg = msg;
            },
            close() {
                this.dialog = false;
                setTimeout(() => {
                    this.editedItem = Object.assign({}, this.defaultItem);
                    this.editedIndex = -1;
                }, 300);
            },

            save() {
                if (this.editedIndex > -1) {
                    if (this.$refs.form.validate()) {
                        axios
                            .post("/Admin/editLibrary", this.editedItem)
                            .then((res) => {
                                if (res.data.status.code == "SUCCESS") {
                                    //window.alert(res.data.msg)
                                    Object.assign(this.lib_list[this.editedIndex], this.editedItem);
                                    this.showSnackbar("#4caf50", "Library Updated Successfully..."); // show snackbar on success
                                    this.onLoad();
                                } else if (res.data.status.code == "NA") {
                                    this.showSnackbar("#b71c1c", "Library Already Present!!!"); // show snackbar on error
                                }
                            })
                            .catch((error) => {
                                window.console.log(error);
                            });
                        this.close();
                    }
                } else {
                    // this.lib_list.push(this.editedItem)
                    axios
                        .post("/Admin/saveLibrary", this.editedItem)
                        .then((res) => {
                            if (res.data.status.code == "SUCCESS") {
                                this.showSnackbar("#4caf50", "Library Added Successfully!!!"); // show snackbar on success
                                this.onLoad();
                            } else if (res.data.status.code == "NA") {
                                this.showSnackbar("#b71c1c", "Library Already Present!!!"); // show snackbar on error
                            }
                        })
                        .catch((error) => {
                            window.console.log(error);
                        });
                    this.close();
                }
            }, //save()

            activeOn(item) {
                console.log(item);
                axios
                    .post("/admin/libraryIsactive", item)
                    .then(() => {
                        // this.sec_mode_list = res.data.user_list;
                        this.showSnackbar("#4CAF50", "Library updated successfully...");
                    })
                    .catch((error) => {
                        this.showSnackbar("#b71c1c", "Something went wrong!!!");
                        console.log(error);
                    })
                    .finally(() => {
                        this.edit_btn_load = false;
                        axios;
                    });
            },

            isValidated() {
                if (this.editedItem.roleLinkId) {
                    return true;
                } else {
                    if (!this.editedItem.roleLinkId) {
                        this.errorMsg.roleLinkId = true;
                    }

                    return false;
                }
            }, // .....end of isValidated()
        },
    };
</script>
<style scoped>
    .v-text-field {
        padding-top: 0px;
        margin-top: 0px;
    }
    .v-icon-size {
        font-size: 20px;
    }
    .edit-avatar {
        transition: 0.3s;
        border: 1px solid #b0bec5;
    }
    .edit-avatar:hover {
        background-color: #b0bec5;
    }
    .edit-v-icon {
        padding-left: 6px;
        font-size: 15px;
    }
    .edit-v-icon:hover {
        color: white;
    }
    .fields {
        padding: 0px !important;
    }
    .add-section {
        display: block;
    }
    .import-box {
        margin: 1rem 1rem 2rem 1rem;
        border: 2px solid;
        padding: 1rem 2rem 1rem 2rem;
        text-align: center;
        background: #ffffffb5;
    }
    .label {
        color: black;
        font-size: 16px;
        font-weight: 400;
    }
    .button {
        background: white !important;
    }
    .set-btn {
        margin: 0rem 1rem 0rem 1rem;
        padding-top: 1rem;
    }
    .button:hover {
        color: white;
        background: black !important;
    }
    .file-warn {
        font-style: italic;
        color: red;
        text-align: center;
        margin: 0;
    }
</style>
